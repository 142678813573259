// import React from 'react'
// import GalleryItem from "../src/components/Gallery/GalleryItem";
// import uniqid from "uniqid"
// import { useStaticQuery, graphql} from "gatsby";

// export const RenderHomeGallery = () => {
//   const data = useStaticQuery(graphql`
//       query {
//           allFile(filter: {relativeDirectory: {eq: "homegallery"}}, sort: {name: ASC}) {
//               nodes {
//                   childImageSharp {
//                       gatsbyImageData(
//                           placeholder: BLURRED
//                           quality: 90
//                           transformOptions: {cropFocus: CENTER}
//                           layout: CONSTRAINED
//                       )
//                   }
//               }
//           }
//       }
//   `)
//   const galleryImages = data.allFile.nodes.map(n => {
//     return n.childImageSharp
//   })
//   let itemsLength = galleryImages.length
//   let rowCount = Math.ceil(itemsLength/3)
//   let itemOffset = 3
//   let offsetMultiplayer = 1
//   let swapFlag = false
//   let resultArray = []
//
//   for(let i = 0; i < rowCount ; i++, offsetMultiplayer++, swapFlag = !swapFlag) {
//     const tempArr = galleryImages.slice(i*itemOffset, itemOffset*offsetMultiplayer)
//     const slicedArray = tempArr.map((item, index) => {
//       if(!swapFlag) {
//         if(index===0){
//           return <GalleryItem image={item} key={uniqid()} size="small" />
//         }
//         if(index===1) {
//           return <GalleryItem key={uniqid()} image={item} size="big" />
//         }
//         // if(index===2) {
//         //   return <component image={item} size="full" />
//         // }
//       }else {
//         if(index===0){
//           return <GalleryItem key={uniqid()} image={item} size="big" />
//         }
//         if(index===1) {
//           return <GalleryItem key={uniqid()} image={item} size="small" />
//         }
//       }
//       if(index===2) {
//         return <GalleryItem key={uniqid()} image={item} size="full" />
//       }
//       return null
//     })
//     resultArray = [...resultArray,...slicedArray]
//   }
//   return resultArray
// }
export const spliceIntoChunks = (arr, chunkSize) => {
  const res = [];
  if(arr && arr.length) {
    while (arr.length > 0) {
      const chunk = arr.splice(0, chunkSize);
      res.push(chunk);
    }
  }
  return res;
}
let pluralDict = {
  "kitchen": "kitchens",
  "living room": "living rooms",
  "bathroom": "bathrooms",
  "bar area": "bar areas",
  "dining outdoor": "dining outdoors",
  "pool area": "pool areas",
  "bedroom": "bedrooms",
  "outdoor kitchen": "outdoor kitchens",
  "home office": "home offices",
  "walk in closet": "walk in closets",
  "entertainment room": "entertainment rooms",
  "children's room": "children's rooms",
  "wine cellar": "wine cellars",
  "landscape": "landscapes",
  "artwork": "artworks",
  "other": "others",
  "staircase": "staircases",
  "street view": "street views",
  "outdoor lounge": "outdoor lounges"
}

export const pluralizeWord = (singularWord) => {
    return pluralDict[singularWord]
}
export const sortModulesAndAssets = (modules, order = "asc") => {
  if (modules.length > 0) {
    return modules.sort((a, b) => {
      if (a.position !== undefined && b.position !== undefined) {
        if (order === "asc") {
          return parseInt(a.position) - parseInt(b.position)
        } else {
          return parseInt(b.position) - parseInt(a.position)
        }
      }
      return 0
    })
  }
  return null
}
