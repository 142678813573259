import React from "react"
import styled from "styled-components";
export const SectionTitle = styled.h3`
  display:block;
  position: relative;
  //margin-left: 10.7692308%;
  margin-top:0;
  text-align: center;
  //width:100%;
  margin-left: auto;
  margin-right:auto;
  // transform: translateX(-50%);
  color:${({color}) => color ? color : "#161B1F"};
  padding-bottom: 8px;
  margin-bottom:42px;
  font-family: "Gill Sans";
  font-size: calc(18px + (24 - 18) * ((100vw - 300px) / (1200 - 300)));
  font-weight: 300;
  // min-width:300px;
  line-height: 27.27px;
  text-transform: uppercase;
  //border-bottom: 1px solid #15353B;
  &:after {
   position: absolute;
   content: "";
   width:100px;
   height:1px;
   background-color: ${({color}) => color ? color : "#ACADAF"};
   bottom:0;
   left: 50%; 
   transform: translateX(-50%);
  }
`
