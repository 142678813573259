
import React, { useRef, lazy, Suspense, useEffect, useState } from "react";
import styled from "styled-components";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import {SlArrowLeft, SlArrowRight} from "react-icons/sl"
import { Link } from "gatsby";
import PageTitle from "./page-title";
import { BackgroundOverlay } from "./TopBanner/styles";
import { CarouselItem } from "./CarouselItem";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { pluralizeWord } from "../../utils/helpers";



// const TinySlider = lazy(() => import("tiny-slider-react") )
const SlickCarousel = lazy(() => import("react-slick") )


const HomeCarousel = styled(({ className, nodes, plural, items , linkText, withOutOverlay, width, height, options}) => {
  const isSSR = typeof window === "undefined"

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className}  onClick={onClick} className="right">
        <SlArrowRight size={45} />
      </div>
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className}  onClick={onClick} className="left">
        <SlArrowLeft size={45}  />
      </div>
    );
  }

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: options?.slidesToShow ? options.slidesToShow : 2,
    slidesToScroll: options?.slidesToScroll ? options.slidesToScroll : 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: options?.responsive ? options.responsive : [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    !isSSR && <Suspense fallback={<div />}>
      <div className={className}>
        <SlickCarousel
          {...settings}
        >
          { nodes && nodes.length > 0 ?
            nodes.map(n => {
              return <CarouselItem maxItemHeight={options ? options.maxItemHeight : null} maxItemWidth={options ? options.maxItemWidth : "100%"} key={n.uid}>
                <div className="info-wrapper">
                  { !withOutOverlay && <BackgroundOverlay /> }
                  <GatsbyImage alt={n.alt ? n.alt : "js image"} image={getImage(n.image)} />
                  {n.title && <div className="info-box">
                    <div className="text">
                      <PageTitle color="white">{!plural ? n.title : pluralizeWord(n.title.toLowerCase())}</PageTitle>
                      {n.pdf ? <a href={n.pdf} target="_blank">{ linkText || "View more"}</a>  : <Link to={n.value}>{ linkText || "View more"}</Link> }
                    </div>
                  </div>}
                </div>
              </CarouselItem>
            }) :
            <>
              <CarouselItem>
                <StaticImage src="../images/homegallery/gallery_pic_3.jpg" alt="image" />
              </CarouselItem>
              <CarouselItem>
                <StaticImage src="../images/homegallery/gallery_pic_6.jpg" alt="image" />
              </CarouselItem>
              <CarouselItem>
                <StaticImage src="../images/homegallery/gallery_pic_9.jpg" alt="image" />
              </CarouselItem>
              <CarouselItem>
                <StaticImage src="../images/homegallery/gallery_pic_3.jpg" alt="image" />
              </CarouselItem>
            </>
          }

        </SlickCarousel>
      </div>
    </Suspense>
  );
})`
  position:relative;
  overflow:hidden;
  width:100%;
  .slick-slide {
    height:100%;
    min-height: ${({options}) => options?.mobileHeight ? options.mobileHeight : "auto !important"};
    max-height: ${({options}) => options?.mobileMaxHeight ? options.mobileMaxHeight : "100%"};
    @media screen and (min-width: 640px) {
      min-height: ${({options}) => options?.desktopHeight ? options.desktopHeight : "auto !important"};
    }
  }
  .slick-prev {
    left: 25px;
    z-index: 100;
  }
  .slick-next {
    right: 25px;
    z-index: 100;
  }
  .slick-track {
    display:flex;
    @media screen and (min-width: 900px) {
      gap: 10px;
    }
  }
  .gatsby-image-wrapper {
    min-height: ${({options}) => options?.mobileHeight ? options.mobileHeight : "auto !important"};
    @media screen and (min-width: 640px) {
      min-height: ${({options}) => options?.desktopHeight ? options.desktopHeight : "auto !important"};
    }
  }
  .left {
    position: absolute;
    z-index: 105;
    color: ${({press}) => press ? "#161B1F" : "white"};
    cursor: pointer;
    left: ${({press}) => press ? "35px" : "1.5%"};
    svg {
      width:30px !important;
      height:30px !important;
    }
    @media screen and (min-width: 900px) {
      left:${({press}) => press ? "15px" : "3%"};
      svg {
        width:47px !important;
        height:47px !important;
      }
    }
    top: 50%;
    transform: translateY(-50%)
  }

  .right {
    position: absolute;
    z-index: 105;
    color: ${({press}) => press ? "#161B1F" : "white"};
    cursor: pointer;
    right: ${({press}) => press ? "35px" : "1.5%"};
    svg {
      width:30px !important;
      height:30px !important;
    }
    @media screen and (min-width: 900px) {
      right:${({press}) => press ? "15px" : "3%"};
      svg {
        width:47px !important;
        height:47px !important;
      }
    }
    top: 50%;
    transform: translateY(-50%)
  }
  .slick-list {
    width: 100%;
    @media screen and (min-width: 640px) {
      width:${({press}) => press ? "90%" : "80%"};
    }
    margin:auto;
    overflow: ${({press}) => press ? "hidden" : "visible !important"};
  }
  .info-wrapper {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    z-index: 100;
    .info-box {
      position: absolute;
      -webkit-transform: translate3d(0,0,0);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      transition: all .3s ease-in;
      z-index:115;
      padding-bottom:25px;
      .text {
        display: none;
        text-align: center;
      }
      a {
        color:white;
        text-transform: uppercase;
      }
    }
  }
  .slick-active {
    .info-wrapper {
      .info-box {
        .text {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap:15px;
        }
      }
    }
  }
`;

export default HomeCarousel;

