import React from "react"
import Layout from "../components/layout";
import Seo from "../components/seo";
import { graphql } from "gatsby";
import { Container } from "../components/Layout/Container";
import { SectionTitle } from "../components/Layout/SectionTitle";
import { Section } from "../components/Layout/Section";
import TopBanner from "../components/TopBanner";
import SimpleHomePageCarousel from "../components/SlickCarousel";

const Index = ({pageContext, data, path}) => {
 // console.log("Path: ", path)
  const bannerImage = data.template.sections[2].items[0].imageFile.publicURL
  const title = data.template.sections[0].items[0].locales.find(l => l.iso === pageContext.lang).value
  const inqueries = data.template.sections[1].items[0].locales.find(l => l.iso === pageContext.lang).value
  const allPress =data.allPress.nodes.map(n => {
    return {
      uid: n.uid,
      title: n.locales.find(l => l.iso === pageContext.lang).title,
      value: n.locales.find(l => l.iso === pageContext.lang).value,
      image:  n.imageFile.childImageSharp,
      pdf: n.hardCopyFile?.publicURL
    }
  })
  return (
    <Layout pathName={path} bannerImage={bannerImage} insidePage breadCrumbLinks={pageContext.breadCrumb}>
      <Container>
        <SectionTitle>
          {title}
        </SectionTitle>
        <SimpleHomePageCarousel linkText="Read" press options={{
          slidesToShow: 5,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        }} nodes={allPress} />
      </Container>
      <Container margin="100px auto 50px auto">
        <Section>
          <SectionTitle>
            {inqueries}
          </SectionTitle>
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            flexDirection: "column",
            gap: "25px",
            fontSize: "18px"
          }} className="content">
            <p style={{textAlign: "center", lineHeight: "30px"}}><strong style={{fontWeight:600}}>Mail: </strong><br />{data.department.contacts.find(c => c.type === 'EMAIL').value}</p>
            <p style={{textAlign: "center", lineHeight: "30px"}}><strong style={{fontWeight:600}}>Contact number: </strong><br />{data.department.contacts.find(c => c.type === 'PHONE_PLAIN').value}</p>
          </div>
        </Section>
      </Container>
      <Container width="full-size">
        <Section>
          <TopBanner maxHeight="600px" style={{marginBottom: "60px"}} insidePage  image={bannerImage}/>
        </Section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
    query($templateName: String) {
        department(name: {eq: "main"}) {
            name
            contacts {
                type
                value
            }
        }
        allPress {
            nodes {
                uid
                hardCopyFile {
                    publicURL
                }
                locales {
                    iso
                    title
                    value
                }
                imageFile {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        } 
      template(name: {eq: $templateName}) {
        locales {
          iso
          metaDescription
          metaKeywords
          metaTitle
          value
        }
        sections {
          items {
            key
          ... on DataItemAssetGet {
              __typename
              imageFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, layout: FULL_WIDTH)
                }
                publicURL
              }
            }
          ... on DataItemTextGet {
              __typename
              locales {
                iso
                value
              }
            }
          }
        }
  }
    }
`
export const Head = ({data, pageContext}) => {
  const { metaTitle, metaDescription} = data.template.locales.find(l => l.iso === pageContext.lang)
  return  <Seo title={metaTitle} description={metaDescription} />
}
export default Index
