import styled from "styled-components"

export const CarouselItem = styled.div`
   width:100%;
  .gatsby-image-wrapper {
    position:relative;
    width:100%;
    max-height:${({maxItemHeight}) => maxItemHeight ? maxItemHeight : "280px"};
    max-width: ${({maxItemWidth}) => maxItemWidth ? maxItemWidth : "100%"};
    overflow: hidden;
    min-height:500px;
    height:${({mobileHeight}) => mobileHeight ? mobileHeight : "400px"};
    //&:before {
    //  position: absolute;
    //  background: rgba(0, 0, 0, 0.45);
    //  width:100%;
    //  top:0;
    //  left:0;
    //  bottom:0;
    //  content: "";
    //  -webkit-transform: translate3d(0,0,0);
    //  z-index: 100;
    //}
  }
  @media screen and (min-width: 768px){
    //width: 
    width:${({width}) => width ? width : "600px"}
    height:${({height}) => height ? height : "576px"}
    img {
      object-fit: cover;
    }
    .gatsby-image-wrapper {
      height:${({height}) => height ? height : "inherit"};
    }
  }
`
